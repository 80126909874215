/* File: App.css */
/* File: App.css */
.App-Section {
  font-family: 'Artemus';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-head {
  font-family: 'Artemus';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-shadow-custom {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.flip-card-subscribe {
  background-color: transparent;
  width: 180px;
  height: 200px;
  perspective: 1000px;
  border-radius: 16px;
}

@media (min-width: 732px) and (max-width: 1033px) {
  .flip-card-subscribe {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 730px) {
  .flip-card-subcribe {
    width: 100px;
    height: 100px;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-subscribe:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.fade-in-image {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

strong {
  font-weight: bolder;
}


/* From Uiverse.io by AnthonyPreite */ 
.main__heading {
  font-weight: 600;
  font-size: 2.25em;
  margin-bottom: 0.75em;
  text-align: center;
  color: #eceff1;
}

.cards {
  position: relative;
}

.card {
  --flow-space: 0.5em;
  --hsl: var(--hue), var(--saturation), var(--lightness);
  flex: 1 1 14rem;
  padding: 1.5em 2em;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  align-items: start;
  gap: 1.25em;
  color: #eceff1;
  background-color: #000;
  border: 1px solid white;
  border-radius: 15px;
}

.card:nth-child(1) {
  --hue: 165;
  --saturation: 82.26%;
  --lightness: 51.37%;
}

.card__bullets {
  line-height: 1.4;
}

.card__heading {
  font-size: 1.05em;
  font-weight: 600;
}

.card__price {
  font-size: 1.75em;
  font-weight: 700;
}

.flow > * + * {
  margin-top: var(--flow-space, 1.25em);
}

.cta {
  display: block;
  align-self: end;
  margin: 1em 0 0.5em 0;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  outline: 1px solid white;
  padding: 0.7em;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
}

.card:hover {
  --lightness: 80%;
  background: #ffffff80;
  color: #000;
  outline: 1px solid rgb(255, 255, 255);
  box-shadow: inset 20px 0 80px rgba(255, 0, 255, 0.747),
    inset -20px 0 80px #FB9236, inset 20px 0 300px #F23985, inset -20px 0 300px #FB9236,
    0 0 50px #fff, -10px 0 80px #F23985, 10px 0 80px #FB9236;
  transition: all ease-in-out 0.3s;
}

.card:hover > .cta {
  outline: none;
  background-color: #0d0d0d;
}

.flip-box {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px; /* 3D effect */
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* Rotate the back side */
.flip-box-back {
  transform: rotateY(180deg);
}

/* Apply the flip when .flipped class is added */
.flip-box-inner.flipped {
  transform: rotateY(180deg);
}


.text-gradient{
  background-image: linear-gradient(to right, #F23985,#FB9236);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@media (min-width: 999px) and (max-width: 1035px) {
  .Flip-screen {
    height: 70vh;
  }
  .circleplay{
    font-size: 40px;
    margin-left: 10px;
  }
  .bussiness_club{
    width: 25%;
  }
  .email_header{
    transform: translateX(-10px);
  }
}

.fa-facebook,.fa-x-twitter,.fa-instagram,.fa-linkedin,.fa-star,.fa-wallet,.fa-dollar-sign,.fa-circle-check,.fa-arrow-up{
  background: -webkit-linear-gradient(right,#E43875,#F98736);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-hover-effect {
  transition: all 0.3s ease-in-out; 
}

.group:hover .button-hover-effect {
  background: white; 
  color: black;
}
