.fade-in {
    animation: fadeIN 1.5s ease-in;
}

@keyframes fadeIN {
    0% { opacity: 0
    };
    100% {
        opacity: 1;
    }

}

.check-gif{
    position: absolute;
    top: 12%;
    scale: 3;

}