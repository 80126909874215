.flip-card {
    background-color: transparent;
    width: 200px;
    height: 200px;
    perspective: 1000px;
}

@media (min-width: 732px) and (max-width: 1033px) {
    .flip-card {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 730px) {
    .flip-card {
        width: 100px;
        height: 100px;
    }
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background: linear-gradient(to bottom, #E438758A, #D7564C8A);
}

.flip-card-back {
    background: linear-gradient(to bottom, #E438758A, #D7564C8A);
    transform: rotateY(180deg);
}

/* ------- */
.QR-Container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 10;
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.custom-clip{
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.Information-tip{
    animation: 10s disappear forwards;
}

@keyframes disappear{
    75%{opacity: 1;}
    100%{opacity: 0;}
}